import { Link } from "@remix-run/react"
import { Logo } from "~/modules/common/component/Logo"
import { Button } from "~/modules/common/ui/button"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/modules/common/ui/sheet"
import { useFeatureFlags } from "~/modules/feature-flags/hooks/useFeatureFlags"

export function Drawer() {
  const flags = useFeatureFlags()
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size={"icon"} variant={"ghost"} className="rounded-full">
          <i className="ri-menu-line ri-xl" />
        </Button>
      </SheetTrigger>
      <SheetContent side={"left"} className="h-full flex flex-col min-w-[500px] w-full">
        <SheetHeader>
          <SheetTitle>
            <Logo />
          </SheetTitle>
        </SheetHeader>

        <div className="flex flex-col gap-4 py-4 flex-grow">
          {flags["feature-report"] && (
            <SheetClose asChild>
              <Link to="/report" viewTransition>
                <div className="p-4 flex flex-row gap-4 hover:bg-slate-100">
                  <img src="/images/report.jpg" width={100} alt="Report" />
                  <div className="prose">
                    <h4>Report</h4>
                    <p>A custom subdivision risk report</p>
                    <Button variant={"outline"}>Get Report</Button>
                  </div>
                </div>
              </Link>
            </SheetClose>
          )}
          {flags["feature-map"] && (
            <SheetClose asChild>
              <Link to="/map" viewTransition>
                <div className="p-4 flex flex-row gap-4 hover:bg-slate-100">
                  <img src="/images/map.png" width={100} alt="Map" />
                  <div className="prose">
                    <h4>Map</h4>
                    <p>Map view with subdivision simulation</p>
                    <Button variant={"outline"}>Go To Map</Button>
                  </div>
                </div>
              </Link>
            </SheetClose>
          )}
        </div>
        <SheetFooter className="prose sm:flex-col justify-start sm:space-x-0">
          <SheetClose asChild>
            <Link to="/privacy-policy" viewTransition>
              Privacy Policy
            </Link>
          </SheetClose>
          <span>© Copyright Trooper {new Date().getFullYear()}</span>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
