import { Link, useNavigation } from "@remix-run/react"
import { useScroll } from "ahooks"

import { type PropsWithChildren, forwardRef } from "react"
import { Logo } from "~/modules/common/component/Logo"
import { cn } from "~/modules/common/util/shadcn"
import { Drawer } from "~/modules/layout/component/Drawer"
import { UserAvatarMenu } from "~/modules/layout/component/UserAvatarMenu"
import { useCurrentRoute } from "../../common/hook/useCurrentRoute"
import { FeedbackForm } from "../../user/component/FeedbackForm"

interface AppBarProps extends PropsWithChildren {
  className?: string
}
export type Ref = HTMLDivElement

export const NavBar = forwardRef<Ref, AppBarProps>(({ children, className, ...other }, ref) => {
  const navigation = useNavigation()
  const currentHandle = useCurrentRoute()
  const navPositionFixed = currentHandle?.topnav?.isFixed
  const showLinks = currentHandle?.topnav?.showLinks ?? false
  const transparent = currentHandle?.topnav?.transparent ?? false

  const scroll = useScroll()
  const isVisible = !scroll || (scroll && scroll.top < 10)

  return (
    <nav
      className={cn(
        "sticky z-10 flex w-full bg-background dark:bg-slate-800 transition ease duration-200 transform",
        navPositionFixed ? "fixed" : "sticky",
        transparent ? "bg-trasparent text-white" : "bg-background text-black border-b",
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-full",
        className,
      )}
      ref={ref}
      {...other}
    >
      <div className="absolute top-0 h-1 w-full">
        <div
          className={cn(
            "h-full bg-gradient-to-r from-blue-500 to-cyan-600 transition-all duration-500 ease-in-out",
            navigation.state === "idle" && "w-0 opacity-0 transition-none",
            navigation.state === "submitting" && "w-1/2",
            navigation.state === "loading" && "w-full",
          )}
        />
      </div>
      <div className="flex flex-row min-h-[64px] w-full items-center justify-between py-2.5 pr-[18px] pl-[6px] sm:pl-[18px]">
        <div className="flex flex-row items-center justify-center">
          <Drawer />
          <Logo className="ml-2" />
        </div>
        {showLinks && (
          <ul className="font-medium flex gap-4">
            <Link to="/" viewTransition>
              Home
            </Link>
          </ul>
        )}
        {children}
        <div className="flex justify-end gap-4">
          <FeedbackForm
            triggerProps={{
              className: "self-end justify-end",
            }}
          />
          <UserAvatarMenu contrast={transparent} />
        </div>
      </div>
    </nav>
  )
})

NavBar.displayName = "AppBar"

export default NavBar
