import { Link } from "@remix-run/react"
import { cn } from "~/modules/common/util/shadcn"

interface LogoProps {
  className?: string
}

export const Logo = ({ className }: LogoProps) => {
  return (
    <Link to="/" className={cn("text-lg", className)} viewTransition>
      TROOPER
    </Link>
  )
}
