import { Form, Link } from "@remix-run/react"
import { useUser } from "~/modules/auth/context/UserContext"
import { Avatar, AvatarFallback } from "~/modules/common/ui/avatar"
import { Button } from "~/modules/common/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/modules/common/ui/dropdown-menu"
import { cn } from "~/modules/common/util/shadcn"
import { AccountNavigation } from "~/modules/user/component/AccountNavigation"

interface AvatarMenuProps {
  contrast: boolean
}

export const UserAvatarMenu = (props: AvatarMenuProps) => {
  const { contrast = false } = props
  const { user } = useUser()
  const initials = user?.email
    ?.split("@")
    .map((n) => n[0].toUpperCase())
    .join("")

  if (!user) {
    return (
      <Link to="/login" viewTransition>
        <Button>Login</Button>
      </Link>
    )
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar
          tabIndex={0}
          role="menu"
          aria-label="User menu"
          className={cn("cursor-pointer", contrast && "text-white border-white")}
        >
          <AvatarFallback className={cn(contrast && "bg-transparent")}>{initials}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />

        <AccountNavigation />

        <DropdownMenuSeparator />
        <Form method="POST" action="/auth/logout" reloadDocument>
          <Button type="submit" size="sm">
            Log out
          </Button>
        </Form>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
