import type { DialogTriggerProps } from "@radix-ui/react-dialog"
import { Form, useFetcher, useNavigate, useNavigation } from "@remix-run/react"
import { useEffect, useState } from "react"
import { Button } from "~/modules/common/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "~/modules/common/ui/dialog"
import { Input } from "~/modules/common/ui/input"
import { Label } from "~/modules/common/ui/label"
import { RadioGroup, RadioGroupItem } from "~/modules/common/ui/radio-group"
import { Textarea } from "~/modules/common/ui/textarea"
import type { action } from "~/routes/_api.feedback"

interface FeedbackFormProps {
  triggerProps?: DialogTriggerProps
}

export const FeedbackForm = (props: FeedbackFormProps) => {
  const fetcher = useFetcher<typeof action>()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data?.success) {
      setOpen(false)
    }
  }, [fetcher.state, fetcher.data])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm" variant={"outline"} icon={"ri-bug-line"} {...props.triggerProps}>
          Feedback
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Submit Feedback</DialogTitle>
        </DialogHeader>
        <fetcher.Form method="POST" action="/feedback" className="space-y-4">
          <div className="space-y-2">
            <Label>Feedback Type</Label>
            <RadioGroup name="type" defaultValue="feature" className="flex gap-4">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="feature" id="feature" />
                <Label htmlFor="feature">Feature Request</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="bug" id="bug" />
                <Label htmlFor="bug">Bug Report</Label>
              </div>
            </RadioGroup>
          </div>

          <div className="space-y-2">
            <Label htmlFor="title">Title</Label>
            <Input id="title" name="title" required />
          </div>

          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              name="description"
              placeholder="Please describe your feedback in detail..."
              required
              className="h-32"
            />
          </div>

          <Button
            type="submit"
            className="w-full"
            loading={fetcher.state === "submitting"}
            disabled={fetcher.state === "submitting"}
          >
            {fetcher.state === "submitting" ? "Submitting..." : " Submit Feedback"}
          </Button>
        </fetcher.Form>
      </DialogContent>
    </Dialog>
  )
}
